import { HttpService } from '../../shared/service/http.service';
import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import axios from 'axios';
import { countries } from '../../shared/modules/country-data-store';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { state } from '@angular/animations';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-update-corporate',
  templateUrl: './update-corporate.component.html',
  styleUrls: ['./update-corporate.component.css']
})
export class UpdateCorporateComponent implements OnInit {
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  emailDomPattern: any = '^[a-z0-9]{2,30}$';
  phonePattern: any = '^((\\+91-?)|0)?[0-9]{10}$';
  therapistDetails: any;
  specificToCountry: boolean;
  corporateDetails: any = {};
  file_name: any;
  addresses: any;
  loading: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: any = [    {name: 'Lemon'},
  {name: 'Lime'},
  {name: 'Apple'},]
  announcer = inject(LiveAnnouncer);
  disbaledButton: boolean = false;


  @Input() options: string[] = [];


  constructor(private router:Router, private http: HttpService,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private callendlyAccessTokenCall: CalendlyAccesTokenService,) { }

  ngOnInit(): void {
    this.getIndCorporate()
  }

  onSubmit(): void {
    console.log("enter submit button");
    // if(this.selectedStateAddress.length>0){
    //   this.therapistDetails.AvailabilityRegions = this.selectedStateAddress
    // }
    // console.log(this.therapistDetails);
    
    this.addCorporateToServer()
  }
  selectedStateAddress(selectedStateAddress: any) {
    throw new Error('Method not implemented.');
  }


  addCorporateToServer(): void {
    let postCorporateData = {}
    // if (this.specificToCountry == true) {
      postCorporateData = 
      {
        "_id": sessionStorage.getItem('updatepathid'),
        "email": this.corporateDetails.email,
        "companyName": this.corporateDetails.companyName,
        "pointOfContact": parseInt(this.corporateDetails.pointOfContact),
        // "Password": this.corporateDetails.Password,
        "phone": parseInt(this.corporateDetails.phone),
        "website": this.corporateDetails.website,
        "noOfLicense": parseInt(this.corporateDetails.noOfLicense),
        "address": this.corporateDetails.address,
        "country": this.corporateDetails.country,
        "state": this.corporateDetails.state,
        "zipcode": parseInt(this.corporateDetails.zipcode),
        // "emailDomain": this.textList,
        "emailDomain": this.corporateDetails.emailDomain,
    }
    // } else if(this.specificToCountry == false) {
    //   postDataCountry = 
    //     { 
    //       "Email": this.corporateDetails.Email,
    //       "Companyname": this.corporateDetails.Companyname,
    //       "Pointofnumber": this.corporateDetails.Pointofnumber,
    //       // "Password": this.corporateDetails.Password,
    //       "Phone": this.corporateDetails.Phone,
    //       "Website": this.corporateDetails.Website,
    //       "Nooflicence": this.corporateDetails.Nooflicence,
    //       "Address": this.corporateDetails.Address,
    //       "Country": this.corporateDetails.Country,
    //       "State": this.corporateDetails.State,
    //       "Zipcode": this.corporateDetails.Zipcode,
    //     }
    // }
    
     console.log(postCorporateData);

    this.loading = true;
    // this.spinnerService.show();
    console.log(this.corporateDetails);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    console.log(token);

    console.log(this.corporateDetails);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    if (postCorporateData) {
      this.disbaledButton = true
    }
    this.http
      .post('corporates/updateCorporate', postCorporateData, options)
      .subscribe(
        (res: any) => {
          this.loading = false;
          //console.log(res);
          //this.addTherapistToOrg();
        //   Swal.fire(
        //   'Success',
        //   'A mail has been sent to the therapist email id to set availability!',
        //   'success'
        // );
        this.router.navigate(['dashboard/corporates']);
        this.spinnerService.hide();
        },
        (error: any) => {
          this.loading = false;
          console.log(error);
          // Swal.fire('Error!', error?.error.message, 'error');
          this.spinnerService.hide()
        }
      );
  }

  getIndCorporate(): void {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    // this.spinner.show()
    this.http
      .get(`corporates/getCorporateDetails?id=${sessionStorage.getItem('updatepathid')}`, options)
      .subscribe((res: any) => {
        console.log(res.data);
        if (res) {
          this.corporateDetails = res.data
        }
      });
  }

  public back() {
    this.router.navigate(['/dashboard/corporates']);
    sessionStorage.setItem('path','/dashboard/corporates');
  }

  userInput = '';
  textList: string[] = [];

  addTextToList(): void {
    if (this.userInput.trim() !== '') {
      this.textList.push(this.userInput.trim());
      this.userInput = ''; // Clear the input field after adding to the list
    }
  }

  removeText(index: number): void {
    this.textList.splice(index, 1);
  }
  

  
}

