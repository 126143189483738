import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-admin-profile',
  templateUrl: './update-admin-profile.component.html',
  styleUrls: ['./update-admin-profile.component.css'],
})
export class UpdateAdminProfileComponent implements OnInit {
  adminDetails: any = {};
  selectedDay: any = '';
  slotDetails: any = {};
  imageBaseUrl: any = environment.imageUrl
  slotArray: any = [];
  public loading: boolean = false;
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  phonePattern: any = '^((\\+-?)|0)?[0-9]$';
  _file: any;
  fileName = '';
  userImage: any;
  baseUrl: any = environment.imageUrl;

  constructor(
      private http: HttpService, private router: Router,
      private SpinnerService: NgxSpinnerService) {}

  ngOnInit(): void {
    
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;
    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.SpinnerService.show(); 
    this.http
      .get('user/getUserById/' + userId, options)
      .subscribe((res: any) => {
        this.adminDetails = res;
        this.userImage = this.adminDetails.profileImageUrl;
        this.SpinnerService.hide(); 
      });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      const formData = new FormData();

      formData.append('file', file);
      this._file = formData;
    }
  }

  submit(): void {
    // multipart/form-data; boundary=<calculated when request is sent>

    let currentUser: any = sessionStorage.getItem('currentUser');
    let userId: any = JSON.parse(currentUser).userId;

    this.SpinnerService.show(); 
    const upload$ = this.http.post('user/updateImage/' + userId, this._file);

    upload$.subscribe(
      (res: any) => {
        if (res) {
          this.loading = false;
          //console.log(res);
          sessionStorage.setItem('profile_image',this.imageBaseUrl+res.profileImageUrl)
          window.location.reload();
          this.adminDetails.profileImageUrl = res.profileImageUrl;
          this.SpinnerService.hide(); 
          this.ngOnInit();
        }
      },
      (error: any) => {
        //console.log(error);
        this.loading = false;
        this.SpinnerService.hide();
      }
    );
  }

  public goBack() {
    this.router.navigate(['dashboard/admin-profile']);
    sessionStorage.setItem('path','dashboard/admin-profile')
  }

  onSubmit(): void {
    //console.log(this.adminDetails);
    this.loading = true;

    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;
    //console.log(token);

    //event handler for the select element's change event

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .put('user/update/' + userId, this.adminDetails, options)
      .subscribe(
        (res: any) => {
          if (res) {
            this.loading = false;

            //console.log(res);
            Swal.fire('Done!', 'Profile updated successfully.', 'success');
          }
        },
        (error: any) => {
          //console.log(error);
          this.loading = false;

          Swal.fire('Error!', error?.statusText, 'error');
        }
      );
  }
}
