import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from 'src/app/shared/modules/country-data-store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css'],
})
export class AdminProfileComponent implements OnInit {
  constructor(
    private http: HttpService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}
  therapistDetails: any;
  timeZone: any;
  name: any;
  role: any;
  mail: any;
  phone: any;
  region: any;
  public countries: any = countries;

  userImage: any;
  baseUrl: any = environment.imageUrl;

  ngOnInit(): void {
    this.spinner.show();

    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    try {
      this.http
        .get('user/getUserById/' + userId, options)
        .subscribe((res: any) => {
          this.therapistDetails = res;
          this.timeZone = res.TimeZone;
          this.name = res.FirstName;
          this.role = res.Role;
          this.mail = res.Email;
          this.phone = res.Phone;
          let index = countries.findIndex((x) => x.code === res.Region);
          this.region = countries[index].name;
          this.userImage = this.therapistDetails.profileImageUrl;
          sessionStorage.setItem(
            'profile_image',
            this.baseUrl + this.therapistDetails.profileImageUrl
          );
        });
    } catch (error) {
      console.warn('Error while getting user by id.', error);
    } finally {
      this.spinner.hide();
    }
  }

  public setRoute(path: any) {
    this.router.navigate([path]);
    sessionStorage.setItem('path', path);
  }
}
