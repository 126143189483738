import { Platform } from '@angular/cdk/platform';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexYAxis,
  ApexXAxis,
  ApexFill,
  ApexTitleSubtitle,
  ApexStroke,
  ApexMarkers,
  ApexGrid,
  ApexAnnotations,
  ApexTooltip
} from "ng-apexcharts";
import { Date_Filters } from 'src/app/shared/models/model';
import { monthNames, weeks } from 'src/app/shared/modules/user-data-strore';
import { HttpService } from 'src/app/shared/service/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

export type ChartOptionsBar = {
  toolbar: any,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  tooltip: ApexTooltip;
};
export type ChartOptionsPie = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: any;
};
export type ChartOptionsArea = {
  toolbar: any,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  tickAmount: any;
  annotations: ApexAnnotations;
};
export type ChartOptionsLine = {
  toolbar: any,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
};
export type ChartOptionsHorizontal = {
  toolbar: any,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  title: any;
  annotations: ApexAnnotations;
  stroke: ApexStroke
};

export const series = {
  monthDataSeries1: {
    prices: [
      61.85,
      71.0,
      51.9,
      61.5,
      73.7,
      84.7,
      50.5,
      85.3,
      62.85,
    ],
    dates: [
      "13 Nov 2017",
      "14 Nov 2017",
      "15 Nov 2017",
      "16 Nov 2017",
      "17 Nov 2017",
      "20 Nov 2017",
      "21 Nov 2017",
      "22 Nov 2017",
      "23 Nov 2017",
      
    ]
  },
};

@Component({
  selector: 'app-corporate-admin-dashboard',
  templateUrl: './corporate-admin-dashboard.component.html',
  styleUrls: ['./corporate-admin-dashboard.component.css']
})

export class CorporateAdminDashboardComponent implements OnInit {
  @ViewChild("chartPie") chartPie: ChartComponent;
  public chartOptionsBar: Partial<ChartOptionsBar> ;
  @ViewChild("chartBar") chartBar: ChartComponent;
  public chartOptionsPie: Partial<ChartOptionsPie> ;
  @ViewChild("chartArea") chartArea: ChartComponent;
  public chartOptionsArea: Partial<ChartOptionsArea> ;
  @ViewChild("chartLine") chartLine: ChartComponent;
  public chartOptionsLine: Partial<ChartOptionsLine> ;
  @ViewChild("chartHorizontal") chart: ChartComponent;
  public chartOptionsHorizontal: Partial<ChartOptionsHorizontal> ;

  renderVolumeGraph: Boolean = false
  renderOthers: Boolean = false

  userStatusCount: any = []
  userData:any[]

  volumeAvg: any = 0
  pitchAvg: any = 0
  intensityAvg: any = 0
  speechRateAvg: any = 0

  volumeSeries: any = []
  volumeTimestamp: any = []

  public startDate: Date;
  public endDate: Date;

  dateFlters: Date_Filters[] = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'This week', value: 'this-week' },
    { name: 'Last week', value: 'last-week' },
    { name: 'This Month', value: 'this-month' },
    { name: 'Last Month', value: 'last month' }
  ];

  selectedFilters: any = "";
  userFilters: any = "";
  public weeks: any = weeks;
  public months: any = monthNames;
  public daterange: any = {};
  //activeUserNumber: any = 1;
  //inactiveUserNumber: any = 1;

  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  constructor(private http: HttpService, private spinner: NgxSpinnerService,) { 
    
    this.spinner.show();
  }

  ngOnInit(): void {
    this.getUserStatusCount()
    this.getAnalytics()
    // this.getUsers()
    // this.getVolumeData()
    this.getAverageData()

  }

  getAnalytics(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem("token"),
    });
    let options = { headers: headers };
    this.http
      .get('serverSync/getAnalyticsDataGraphForCorporate/'+sessionStorage.getItem("CorporateCode"), options)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.volumeAvg = res.avgVolume
          this.pitchAvg = res.avgPitch
          this.intensityAvg = res.avgIntensity
          this.speechRateAvg = res.avgSpeechRate
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getUserStatusCount(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionStorage.getItem("token"),
    });
    let options = { headers: headers };
    this.http
      .get('corporates/CorporateUserCount/'+sessionStorage.getItem("CorporateCode"), options)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.userStatusCount.push(res.active)
          this.userStatusCount.push(res.inactive)
          console.log(this.userStatusCount);
          this.initPieChart()
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getVolumeData(){
    let token: any = sessionStorage.getItem('token');
    let corporateCode: any = sessionStorage.getItem('CorporateCode');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('corporates/getCorporateUserVolumeGraphData/'+corporateCode, options).subscribe((res:any)=>{
      console.log("Volume Rs",res[0].results);

      var volumeData = res[0].results
      var volumeLength = res[0].results.length
      if(volumeLength > 10){
        volumeLength = 10
      }
      var tempVolume:any = []
      for(var i=0;i<volumeLength;i++){
        tempVolume.push({volume:volumeData[i].result.avgDbLevel,timestamp:volumeData[i].result.timestamp})
      }
      this.updateBarChart(tempVolume)
    },(err)=>{
      console.log(err);
    })
  }

  getAverageData(){
    let token: any = sessionStorage.getItem('token');
    let corporateCode: any = sessionStorage.getItem('CorporateCode');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('corporates/getCorporateUserGraphData/'+corporateCode, options).subscribe((res: any) => {
      console.log("Average",res);
      // this.volumeAvg = res.VolumeResult[0].averageValue.toFixed(2)
      var pitchCount = 0
      var intensityCount = 0
      var speechRateCount = 0
      var pC = 0
      var iC = 0
      var spC = 0

      var tempPitchArr = []
      var tempIntensityArr = []
      var tempSpeechRateArr = []

      for(var i=0;i<res.length;i++){
        // console.log(res[i]);
        switch (res[i].dataFor) {
          case "volume":
            this.updateBarChart(res[i].data)
            break;
          case "pitch":
            this.updateLineChart(res[i].data)
            break;
          case "intensity":
            this.updateAreaChart(res[i].data)
            break;
          case "speechRate":
            this.updateHoriBarChart(res[i].data)
            break;
          case "pauses":
            
            break;
          case "fillers":
            
            break;
          default:
            break;
        }
      }

      

      // for(var i=0;i<res.otherResult.length;i++){
      //   for(var j=0;j<res.otherResult[i].minutesDataResult.length;j++){
      //     if(res.otherResult[i].minutesDataResult[j].ValueFor == "pitch"){
      //       tempPitchArr.push({"timestamp":res.otherResult[i].minutesDataResult[j].timestamp,"pitch":res.otherResult[i].minutesDataResult[j].avgValue})
      //       // console.log("pitch",res.otherResult[i].minutesDataResult[j].avgValue);
      //       pitchCount += res.otherResult[i].minutesDataResult[j].avgValue
      //       pC++
      //     }
      //     if(res.otherResult[i].minutesDataResult[j].ValueFor == "intensity"){
      //       tempIntensityArr.push({"timestamp":res.otherResult[i].minutesDataResult[j].timestamp,"intensity":res.otherResult[i].minutesDataResult[j].avgValue})
      //       // console.log("intensity",res.otherResult[i].minutesDataResult[j].avgValue);
      //       intensityCount += res.otherResult[i].minutesDataResult[j].avgValue
      //       iC++
      //     }
      //     if(res.otherResult[i].minutesDataResult[j].ValueFor == "speechRate"){
      //       tempSpeechRateArr.push({"timestamp":res.otherResult[i].minutesDataResult[j].timestamp,"speechRate":res.otherResult[i].minutesDataResult[j].avgValue})
      //       // console.log("speechRate",res.otherResult[i].minutesDataResult[j].avgValue);
      //       speechRateCount += res.otherResult[i].minutesDataResult[j].avgValue
      //       spC++
      //     }
          
      //   }
      // }

      // this.pitchAvg = (pitchCount/pC).toFixed(2)
      // this.intensityAvg = (intensityCount/iC).toFixed(2)
      // this.speechRateAvg = (speechRateCount/spC).toFixed(2)

      // tempPitchArr.sort(function(a,b){return b.timestamp - a.timestamp})
      // tempIntensityArr.sort(function(a,b){return b.timestamp - a.timestamp})
      // tempSpeechRateArr.sort(function(a,b){return b.timestamp - a.timestamp})

      // // tempPitchArr = this.removeDuplicatesWithMinDifference(tempPitchArr,0)

      // // tempPitchArr.reverse()
      // // tempIntensityArr.reverse()
      // // tempSpeechRateArr.reverse()

      // console.log("Pitch", tempPitchArr);
      // console.log("Intensity", tempIntensityArr);
      // console.log("SpeechRate", tempSpeechRateArr);

      // var tempPitchLength = tempPitchArr.length | 0
      // var tempIntensityLength = tempIntensityArr.length | 0
      // var tempSpeechRateLength = tempSpeechRateArr.length | 0

      // if(tempPitchLength > 10){
      //   tempPitchLength = 10
      // }
      // if(tempIntensityLength > 10){
      //   tempIntensityLength = 10
      // }
      // if(tempSpeechRateLength > 10){
      //   tempSpeechRateLength = 10
      // }

      // this.updateLineChart(tempPitchArr.slice(0,tempPitchLength))
      // this.updateAreaChart(tempIntensityArr.slice(0,tempIntensityLength))
      // this.updateHoriBarChart(tempSpeechRateArr.slice(0,tempIntensityLength))
      this.renderOthers = true
      this.spinner.hide();

    },(err)=>{
      console.log(err,"failed to response");
      
    });
  }

  removeDuplicatesWithMinDifference(sortedArray:any, minDifference:any) {
    // Check if the array is empty or has only one element
    console.log("Pitch",sortedArray);
    
    if (sortedArray.length <= 1) {
        return sortedArray;
    }

    // Initialize an array to store unique elements
    let uniqueArray = [sortedArray[0]];

    // Iterate through the sorted array
    for (let i = 1; i < sortedArray.length; i++) {
        // If the current element is different from the previous one
        // and the difference is greater than or equal to minDifference, add it to the unique array
        if (sortedArray[i].timestamp !== sortedArray[i - 1].timestamp && (sortedArray[i].timestamp - sortedArray[i - 1].timestamp) >= minDifference) {
            uniqueArray.push(sortedArray[i]);
        }
    }

    return uniqueArray;
}

  getUsers(): void {
    let token: any = sessionStorage.getItem('token');
    let corporateCode: any = sessionStorage.getItem('CorporateCode')
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('corporates/getCorporateUserDetails/'+corporateCode, options).subscribe((res: any) => {
      res.push({LastName:"", FirstName:'All', _id:"all"})
      // console.log(res);
      
      this.userData = res.reverse();

    },(err)=>{
      console.log(err,"failed to response");
      
    });

  }

  initPieChart(){
    //pie
    this.chartOptionsPie = {
      series: this.userStatusCount,
      chart: {
        width: 420,
        type: "pie"
      },
      labels: ["Active", "In Active"],
      legend: {
        show:true,
        position: 'bottom',
        floating: true,
        verticalAlign: 'bottom',
        align:'center'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show:true,
              position: 'bottom',
              floating: true,
              verticalAlign: 'bottom',
              align:'center'
            }
          }
        }
      ],
      // colors:[]
    };
  }
  
  updateLineChart(data:any){
    console.log("Initialize Pitch", data);
    var pitchValue = []
    var timestampValue = []
    var maxPitch = 0
    var minPitch = 100
    data.reverse()
    for(var i = 0; i<data.length; i++){
      pitchValue.push(data[i].avg.toFixed(2))
      timestampValue.push(data[i].date)
      if(maxPitch < data[i].avg){
        maxPitch = data[i].avg
      }
      if(minPitch > data[i].avg){
        minPitch = data[i].avg
      }
    }
    console.log(pitchValue);
    
    console.log(timestampValue.toString())
    //Line
    this.chartOptionsLine = {
      toolbar:{
        show:false
      },
      series: [
        {
          name: "Pitch values",
          data: pitchValue
        }
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "Pitch",
        align: "center"
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.5
      //   }
      // },
      xaxis: {
        categories: timestampValue
      },
      yaxis: {
        title: {
          text: "(Hz)-Hertz"
        },
        min:0,
        max:400
      },
      annotations: {
        yaxis: [
          {
            y: 100, // Your min range value
            y2:350,  
            strokeDashArray: 5,
            borderColor: '#FF0000',
            width: '100%',
            label: {
              borderColor: '#FFA500',
              borderWidth: 10,
              style: {
                color: '#fff',
                background: '#FFA500',
              },
            },
          },
          
        ],
      },
    };
  }

  updateAreaChart(data:any){
    console.log("Initialize Intensity", data);
    var intensityValue = []
    var timestampValue = []
    var maxIntensity = 0
    var minIntensity = 100
    data.reverse()
    for(var i = 0; i<data.length; i++){
      intensityValue.push(data[i].avg.toFixed(2))
      timestampValue.push(data[i].date)
      if(maxIntensity < data[i].avg){
        maxIntensity = data[i].avg
      }
      if(minIntensity > data[i].avg){
        minIntensity = data[i].avg
      }
    }
    console.log(intensityValue);
    
    console.log(timestampValue.toLocaleString());
    
    //area
    this.chartOptionsArea = {
      toolbar:{
        show:false
      },
      series: [
        {
          name: "",
          data: intensityValue
        }
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },

      title: {
        text: "Intensity",
        align: "center"
      },
      labels: timestampValue,
      // xaxis: {
      //   type: "datetime"
      // },
      yaxis: {
        title: {
          text: "(µW/m^2)-Intensity"
        },
        min:0,
        max:maxIntensity
      },
      tickAmount: 6,
      annotations: {
        yaxis: [
          {
            y: 15, // Your min range value
            y2:85,  
            strokeDashArray: 5,
            borderColor: '#FF0000',
            width: '100%',
            label: {
              borderColor: '#FFA500',
              borderWidth: 10,
              style: {
                color: '#fff',
                background: '#FFA500',
              },
            },
          },
          
        ],
      },
      legend: {
        horizontalAlign: "left"
      }
    };
  }

  updateHoriBarChart(data:any){
    console.log("Initialize Speech Rate", data);
    var speechRateValue = []
    var timestampValue = []
    var maxSpeechRate = 0
    var minSpeechRate = 100
    data.reverse()
    for(var i = 0; i<data.length; i++){
      speechRateValue.push(data[i].avg.toFixed(2))
      timestampValue.push(data[i].date)
      if(maxSpeechRate < data[i].avg){
        maxSpeechRate = data[i].avg
      }
      if(minSpeechRate > data[i].avg){
        minSpeechRate = data[i].avg
      }
    }
    console.log(speechRateValue);
    
    console.log(timestampValue.toLocaleString());
    
    //horizontal
    this.chartOptionsHorizontal = {
      toolbar:{
        show:false
      },
      series: [
        {
          name: "basic",
          data: speechRateValue
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: "Speech Rate",
        align: "center"
      },
      xaxis: {
        categories: timestampValue,
        min:0,
        max:200
      },
      annotations: {
        xaxis: [
          {
            x: 120,
            x2: 160,
            strokeDashArray: 5,
            borderColor: '#FF0000',
            label: {
              borderColor: '#FFA500',
              borderWidth: 10,
              style: {
                color: '#fff',
                background: '#FFA500',
              },
            },
            
          }
        ],
      },
    };
  }

  updateBarChart(data:any){
    console.log("Initialize Volume", data);
    var volumeValue = []
    var timestampValue = []
    var maxVolume = 0
    var minVolume = 100
    data.reverse()
    for(var i = 0; i<data.length; i++){
      volumeValue.push(data[i].avg.toFixed(2))
      timestampValue.push(data[i].date)
      if(maxVolume < data[i].avg){
        maxVolume = data[i].avg
      }
      if(minVolume > data[i].avg){
        minVolume = data[i].avg
      }
    }
    console.log(volumeValue);
    
    console.log(timestampValue.toLocaleString());
    this.volumeSeries = volumeValue
    this.volumeTimestamp = timestampValue

    //bar
    this.chartOptionsBar = {
      toolbar:{
        show:false
      },
      series: [
        {
          name: "",
          data: volumeValue
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        background: '#ffffff',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: timestampValue
      },
      yaxis: {
        title: {
          text: "(db)-decibles"
        },
        min:0,
        max:100
      },
      annotations: {
        yaxis: [
          {
            y: 45, // Your min range value
            y2:65,  
            strokeDashArray: 5,
            borderColor: '#FF0000',
            width: '100%',
            label: {
              borderColor: '#FFA500',
              borderWidth: 10,
              style: {
                color: '#fff',
                background: '#FFA500',
              },
            },
          },
          
        ],
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val:any) {
            return  val + " decibels";
          }
        }
      },
      title: {
        text: "Volume",
        offsetY: 0,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };

    this.renderVolumeGraph = true
  }

  filtersUpdate(){
    console.log(this.selectedFilters, this.userFilters);
    
  }
}
