import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/service/http.service';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-corporate-users',
  templateUrl: './corporate-users.component.html',
  styleUrls: ['./corporate-users.component.css']
})
export class CorporateUsersComponent implements OnInit {

  constructor(
    private http: HttpService, 
    private router: Router,
    private spinner: NgxSpinnerService) {}

  userDetails: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userImage: any;
  baseUrl: any = environment.imageUrl;
  therapistImage: any;
  status: any;
  apiBody: any = {};

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  dateConversion(d:any):String{
    if(d != undefined){
      var lrd_arr = d.split("T")[0].split("-")
      var lrt = d.split("T")[1].split(".")[0]
      return lrd_arr[2]+"-"+lrd_arr[1]+"-"+lrd_arr[0]+"\n"+lrt
    }else{
      return "no data received yet";
    }
    
  }

  getUsers(): void {
    this.spinner.show();
    let token: any = sessionStorage.getItem('token');
    let corporateCode: any = sessionStorage.getItem('CorporateCode')
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http.get('corporates/getCorporateUserDetails/'+corporateCode, options).subscribe((res: any) => {
      console.log(res);
      this.userDetails = res.reverse();
      // this.status = this.userDetails.Status;
      this.dtTrigger.next('')
      this.spinner.hide();

    });

  }

  deleteUser(userId: any): void {
    // console.log(userId);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    // console.log(token);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      width: '60vh',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.delete('user/delete/' + userId, options).subscribe((res: any) => {
          //console.log(res);
          window.location.reload();
        }, (err)=> {
          alert('Some errror occured!')
        });
      }
    })
  }

  public activeInactive(status: any,id: string): void {
   // console.log(status);
    let popUpBtnText: any;

    if (status == true) {
      popUpBtnText = 'Yes, Inactive!'
      this.apiBody = {
        "userId": id,
        "isActive": false
      }
    } else {
      popUpBtnText = 'Yes, Activate!'
      this.apiBody = {
        "userId": id,
        "isActive": true
      }
    }
    
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    //console.log(token);


    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };

    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      width: '60vh',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: popUpBtnText

    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        //console.log('Inactive',this.apiBody);
        this.http.post('user/activeInactiveUser',this.apiBody, options).subscribe(
          (res: any) => {
          //console.log(res);
          window.location.reload();
          this.spinner.hide();
        }, (err)=> {
          this.spinner.hide();
          alert('Some errror occured!')
        });

      }
    })
  }

  public addUser(path: string) {
    this.router.navigate([path]);
    sessionStorage.setItem('path',path);
  }

  public viewUser(path: any,id: any) {
    sessionStorage.setItem('path',path+'/'+id);
  }

}
