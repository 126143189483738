import { HttpService } from '../../shared/service/http.service';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { countries } from 'src/app/shared/modules/country-data-store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-therapist-profile',
  templateUrl: './therapist-profile.component.html',
  styleUrls: ['./therapist-profile.component.css'],
})
export class TherapistProfileComponent implements OnInit {
  constructor(
    private http: HttpService,
     private router: Router,
     private spinner: NgxSpinnerService) {}
  therapistDetails: any;
  name: any;
  role: any;
  baseUrl: any = environment.imageUrl;
  userImage: any;
  phone: any;
  email: any;
  region: any;
  id: any;

  ngOnInit(): void {
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    let userId: any = JSON.parse(currentUser).userId;
    
    this.spinner.show();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .get('therapist/getTherapistById/' + userId, options)
      .subscribe((res: any) => {
        //console.log(res);
        this.therapistDetails = res;
        this.name = res.FirstName;
        this.role = res.Role;
        this.email = res.Email;
        this.phone = res.Phone;
        let index = countries.findIndex(x => x.code === res.Region);
        this.region = countries[index].name;
        this.id = res._id;
        this.userImage = this.therapistDetails.profileImageUrl;
        this.spinner.hide();
      });
  }

  logout(): void {
    Swal.fire({
      title: 'Are you sure?',
      //icon: 'warning',
      // showDenyButton: true,
      width: '60vh',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        sessionStorage.clear();
        window.sessionStorage.clear();
        sessionStorage.removeItem('currentUser');
        this.router.navigate(['/']);
      }
    });
  }

  public goToEditProfile(path: any){
    sessionStorage.setItem('path',path);
    this.router.navigate([path]);
  }

  public goTo(path: any) {
    sessionStorage.setItem('path',path);
    this.router.navigate([path]);
  }
}
