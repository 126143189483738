import { HttpService } from '../../shared/service/http.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import axios from 'axios';
import { countries } from '../../shared/modules/country-data-store';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { state } from '@angular/animations';
import { CalendlyAccesTokenService } from 'src/app/shared/service/calendly-acces-token.service';
import { environment } from 'src/environments/environment';

interface SelectCurrencyType{
  price_id:string;
  price:string;
}

@Component({
  selector: 'app-add-therapist',
  templateUrl: './add-therapist.component.html',
  styleUrls: ['./add-therapist.component.css'],
})
export class AddTherapistComponent implements OnInit {
  emailPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  phonePattern: any = '^((\\+91-?)|0)?[0-9]{10}$';
  therapistDetails: any = {};
  public loading: boolean = false;
  public countries: any = countries;
  sessionDetails: any = {};
  userImage: any;
  baseUrl: any = environment.imageUrl;
  fileName = '';
  _file: any;
  gender = null;
  states: any[] = [];
  showAvaliableCountry: boolean = false;
  file_name: any;
  specificToCountry: boolean;
  dropdownListCountry: Array<any> = [];
  selectedItemsCountry: Array<any> = [];
  dropdownSettingsCountry = {};
  selectedCountryArray: any = [];
  dropdownListStates: Array<any> = [];
  selectedItemsStates: Array<any> = [];
  dropdownSettingsStates = {};
  selectedStatesArray: any = [];
  disbaledButton: boolean = false;
  diableLicense: boolean = false;
  form: FormGroup;
  licenseState: Array<any> = [];
  selectedLicensedState = {};
  keyword = 'name';
  currenciesByRegion: any[] = [];
  selectedCurrency: SelectCurrencyType 
  selectedStateAddress: any[] = []
  therapistType: any = ""
  @ViewChild('selectedStates') selectedStates!: ElementRef;


  public addresses: any[] = [{
    State_name: '',
    LicenseFile: ''
  }];

  constructor(
    private http: HttpService,
     private router: Router,
     private fb: FormBuilder,
     private spinnerService: NgxSpinnerService,
     private callendlyAccessTokenCall: CalendlyAccesTokenService,
     ) {}
     


  ngOnInit(): void {
    
    this.form = new FormGroup({
      LicensedState: new FormArray([
        new FormGroup({
          LicenseFile: new FormControl(''),
          State_name: new FormControl(''),
        }),
      ]),
    });
    
    //console.log(this.form);

    this.dropdownListCountry = countries;
    this.selectedItemsCountry = [
      { code: 'IN', code3: 'IND', name: 'India', number: '356' }, 
      { code: 'ID', code3: 'IDN', name: 'Indonesia', number: '360' },
    ];
    this.dropdownSettingsCountry =  {
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "code",
      "textField": "name",
      "selectAllText": "",
      "unSelectAllText": "",
      "clearSearchFilter": true,
      "enableCheckAll": true,
      "itemsShowLimit": 100,
      "limitSelection": 100,
      "searchPlaceholderText": 'Search country',
      "allowSearchFilter": true
    }

    this.selectedItemsStates = [
      {_id: '62c2cb2f38686378006be0ff', Country_code: 'US', State_name: 'Alabama'},
      {_id: '62c2cb2f38686378006be100', Country_code: 'US', State_name: 'Alaska'}
    ];
    this.dropdownSettingsStates =  {
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "_id",
      "textField": "State_name",
      "selectAllText": "",
      "unSelectAllText": "",
      "enableCheckAll": true,
      "itemsShowLimit": 100,
      "clearSearchFilter": true,
      "limitSelection": 100,
      "searchPlaceholderText": 'Search State',
      "allowSearchFilter": true
    }

  }
  

  // addStates() {
    
  //   this.selectedSatet.push({
  //     license: '',
  //     state: ''
  //   });
  // }

  // removeStates(i: number) {
  //   this.selectedSatet.splice(i, 1);
  // }

  // logValue() {
  //   console.log(this.file_name);
  //   console.log(this.selectedSatet);
  // }

  addAddress() {
    this.addresses.push({
      State_name: '',
      LicenseFile: ''
    });
  }

  removeAddress(i: number) {
    this.addresses.splice(i, 1);
    this.selectedStateAddress.splice(i,1);
  }

  logValue() {
    console.log(this.addresses);
  }


  show() {
    this.selectedLicensedState = {"LicenseFile": this.file_name,"State_name":this.therapistDetails.SelectedState};
    // this.licenseState.push(this.selectedLicensedState)
    console.log(this.selectedLicensedState);
  }

  removeQuantity(i:number): void {  
    console.log(i);
    this.licensedState.removeAt(i);
  }

  get licensedState(): FormArray {
    return this.form.get('LicensedState') as FormArray;
  }

  onCountrySelected(): void {
    console.log(this.therapistDetails.Region)
    this.spinnerService.show()
    this.therapistDetails.AvailabilityRegions = null;
    this.states = [];
    this.selectedCurrency = {
      price:"",
      price_id:""
    }
    if (this.therapistDetails.Region != null) {
      this.http
      .get('states/getStates/'+this.therapistDetails.Region)
      .subscribe(
        (res: any) => {
          //console.log(this.therapistDetails.AvailabilityRegions);
          
          this.states = res;
          console.log(this.states);
          if (res.length == 0) {
            this.showAvaliableCountry = true;
            this.specificToCountry = true;
          } else {
            this.showAvaliableCountry = false
            this.dropdownListStates = res;
            this.specificToCountry = false;
          }
          this.spinnerService.hide();
        },
        (error: any) => {
          this.loading = false;
          //console.log(error);
          this.spinnerService.hide()
        }
      );
      this.http.get("therapist/GetTherapistPlanInStripe?country="+this.therapistDetails.Region).subscribe((res:any)=>{
        console.log(res);
        this.currenciesByRegion = res.sessionPrices.reverse();
      },(err)=>{
        console.log(err);
        
      })
    }
  }

  selectCurrency(){
    this.therapistDetails.SessionPriceId = this.selectedCurrency.price_id;
    console.log(this.therapistDetails);
    
  }

  onSubmit(): void {
    console.log(this.selectedStateAddress);
    if(this.selectedStateAddress.length>0){
      this.therapistDetails.AvailabilityRegions = this.selectedStateAddress
    }
    console.log(this.therapistDetails);
    if(this.therapistType == ""){
      this.therapistType = undefined
      return
    }
    this.addTherapistToOrg()
  }

  addTherapistToServer(): void {
    let postDataCountry = {}
    if (this.specificToCountry == true) {
      postDataCountry = 
      { 
        "Email": this.therapistDetails.Email,
        "FirstName": this.therapistDetails.FirstName,
        "LastName": this.therapistDetails.LastName,
        "Password": this.therapistDetails.Password,
        "Phone": this.therapistDetails.Phone,
        "Region": this.therapistDetails.Region,
        "UserName": 'Voece',
        "LicenseFile": this.file_name,
        "SpecificToCountry": this.specificToCountry,
        "AvailabilityRegions": this.therapistDetails.AvailabilityRegions,
        "uType":this.therapistDetails.uType
    }
    } else if(this.specificToCountry == false) {
      postDataCountry = 
        { 
          "Email": this.therapistDetails.Email,
          "FirstName": this.therapistDetails.FirstName,
          "LastName": this.therapistDetails.LastName,
          "Password": this.therapistDetails.Password,
          "Phone": this.therapistDetails.Phone,
          "Region": this.therapistDetails.Region,
          "UserName": 'Voece',
          "SpecificToCountry": this.specificToCountry,
          "LicenseFile": "",
          "AvailabilityRegions": this.addresses,
          "uType":this.therapistDetails.uType
        }
    }
    
     console.log(postDataCountry);

    this.loading = true;
    this.spinnerService.show();
    console.log(this.therapistDetails);
    let currentUser: any = sessionStorage.getItem('currentUser');
    let token: any = JSON.parse(currentUser).token;
    console.log(token);

    console.log(this.therapistDetails);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let options = { headers: headers };
    this.http
      .post('therapist/create', postDataCountry, options)
      .subscribe(
        (res: any) => {
          this.loading = false;
          //console.log(res);
          //this.addTherapistToOrg();
          Swal.fire(
          'Success',
          'A mail has been sent to the therapist email id to set availability!',
          'success'
        );
        this.router.navigate(['dashboard/therapist']);
        this.spinnerService.hide();
        },
        (error: any) => {
          this.loading = false;
          console.log(error);
          Swal.fire('Error!', error?.error.message, 'error');
          this.spinnerService.hide()
        }
      );
  }

  addTherapistToOrg(): void {
    let access_token: any = '';
    access_token = sessionStorage.getItem('accessToken');
    //console.log(access_token);

    let orgId: any = '';
    orgId = sessionStorage.getItem('orgId');
   //console.log(orgId);

    const headers: any = {
      Authorization: 'Bearer ' + access_token,
    };

    axios
      .post(
        `https://api.calendly.com/organizations/${orgId}/invitations`,
        {
          email: this.therapistDetails.Email,
        },
        {
          headers: headers,
        }
      )
      .then((data) => {
        console.log(data);
        
        // Swal.fire(
        //   'Success',
        //   'A mail has been sent to the therapist email id to set availability!',
        //   'success'
        // );
        this.addTherapistToServer();
        this.http.post(`zoomuser/create-zoom-user?auth=${sessionStorage.getItem('ZoomToken')}`,
        {email:this.therapistDetails.Email,
          type:1
        })
        .subscribe(
          (res: any) => {
             console.log (res)   
          },
         (error: any) => {
           console.log(error);
          } );
      
        
      })
      .catch((err) => {
       console.log(err.response.data.message);

       if (err.response.data.message == 'The access token is invalid') {
            this.callendlyAccessTokenCall.callCalendlyToken();

            setTimeout(() => {
              this.addTherapistToOrg();
           }, 3000);
            
       } else {
        Swal.fire(
          'warning',
          err.response.data.message || 'Invalid token',
          'error'
        );
       }
      
      });
  }

  public back() {
    this.router.navigate(['/dashboard/therapist']);
    sessionStorage.setItem('path','/dashboard/therapist');
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.spinnerService.show();
      this.fileName = file.name;

      const formData = new FormData();

      formData.append('file', file);
      this._file = formData;
      //this.therapistDetails.license = this._file;

      const upload$ = this.http.post('therapist/uploadTherapistLicense', this._file);

    upload$.subscribe(
      (res: any) => {
        
        console.log(res.message);
        this.file_name = res.message;
        this.diableLicense = true;
        this.spinnerService.hide();

        if(this.specificToCountry == false) {
          let index: any = sessionStorage.getItem('index');
          console.log(this.addresses[index].LicenseFile = res.message);
  
          if ( this.addresses[0].state != '' && this.addresses[0].LicenseFile != '') {
            this.disbaledButton = true;
          } else {
            this.disbaledButton = false;
          }
        }
        
      },
      (err: any) => {
        Swal.fire('error', 'File upload failed', 'error');
        this.spinnerService.hide();
      }
    );

    }
  }

  SpecificToCountryStates(value: any){
    console.log(this.therapistDetails);
  }

  onItemSelectCountry(item: any) {
    console.log(this.therapistDetails);
    console.log(this.therapistDetails.AvailabilityRegions.length);

    if(this.therapistDetails.AvailabilityRegions.length > 0) {
      this.disbaledButton = true
    } else {
      this.disbaledButton = false
    }
  }

  onItemSelectState(item: any) {
    console.log(item);
    if(this.therapistDetails.AvailabilityRegions.length > 0) {
      this.disbaledButton = true
    } else {
      this.disbaledButton = false
    }
  }

  showConsole(i: any){
    sessionStorage.setItem('index',i);
  }

  showConsoleLog(newValue: any) {
    console.log(newValue);
  }
  
  onSelected(value: any):void {
    // this.selectedTeam = this.teams.nativeElement.value;
    let index: any = sessionStorage.getItem('index');
    console.log(index);
    // console.log(this.selectedStates.nativeElement.value);
    console.log(value);
    this.addresses[index].State_name = value;
    
    // if(this.selectedStateAddress.length>0){
        this.states.forEach(st=>{
          if(value == st.State_name){
            this.selectedStateAddress.push({_id: st._id, State_name: st.State_name});
          }
        })
    // }
    if ( this.addresses[0].state != '' && this.addresses[0].license != '') {
      this.disbaledButton = true;
    } else {
      this.disbaledButton = false;
    }
  }


    account_id = 'nRg_2fvqTLqsFfGjDmcZmw'

    updateTherapistType(e:any){
      console.log(e.target.checked+" "+e.target.value);
      // this.therapistType = type
      if(e.target.checked == false){
        this.therapistType = undefined
        this.therapistDetails.uType = ""
      }else{
        this.therapistType = e.target.value
        this.therapistDetails.uType = e.target.value
      }
    }
    
}