import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import Swal from 'sweetalert2';
import { HttpService } from '../shared/service/http.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-corporate-password-change',
  templateUrl: './corporate-password-change.component.html',
  styleUrls: ['./corporate-password-change.component.css']
})
export class CorporatePasswordChangeComponent implements OnInit {
  token: string
  verifyPassword: Boolean = true
  passwordDetails: any = {}
  isPasswordError: Boolean = false
  id: string
  loading: boolean = false
  constructor(private activatedRoute:ActivatedRoute, private route: Router, private http: HttpService) { 
    this.activatedRoute.queryParams.subscribe(param=>{
      if(param["token"] == undefined){
        console.log("blah blah");
        Swal.fire("Not an autheticated user.\n Please login first")
        this.route.navigate(["/corporate-login"])
      }else{
        let decodeObj = JSON.parse(atob(param["token"].split('.')[1]))
        this.id = decodeObj.id
      }
    })
  }

  ngOnInit(): void {

  }

  validate(): void {
    console.log(this.passwordDetails.password, this.passwordDetails.cpassword);
    if(this.passwordDetails.password != undefined && this.passwordDetails.cpassword != undefined){
      if(this.passwordDetails.password != this.passwordDetails.cpassword && this.passwordDetails.password != "" && this.passwordDetails.cpassword != ""){
        console.log("password mismatch");
        this.isPasswordError = true
      }else{
        this.isPasswordError = false
      }
    }
    
  }

  removeError():void {
    this.isPasswordError = false
  }

  onSubmit(){
    this.validate()
    if(this.isPasswordError == false){
      this.loading = true
      console.log(this.passwordDetails);
      //api to change password
      let data = JSON.stringify({
        "id": this.id,
        "password": this.passwordDetails.password
      });
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      });
      let options = { headers: headers };
      this.http.post("corporates/changeCorporatePassword",data,options).subscribe(
        (res:any)=>{
          if(res.success == true){
            Swal.fire("Password Changed","Please login to continue").then(result=>{
              this.route.navigate(["corporate-login"])
            })
          }
        },
        (err:any)=>{
          console.log(err);
          Swal.fire("Something went wrong")
        }
      )
    }
    
  }

}
